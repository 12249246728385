import '../assets/css/bootstrap.scss';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';

import { createApp } from 'vue';

import { install } from 'vue3-recaptcha-v2';
import Products from '@/components/Products.vue';
import FeatureHighlights from '@/components/FeatureHighlights.vue';
import AboutUs from '@/components/AboutUs.vue';
import PrivacyPolicyPage from '@/pages/website/PrivacyPolicyPage.vue';
import TermsOfServicePage from '@/pages/website/TermsOfServicePage.vue';
import Newsroom from '@/components/Newsroom.vue';
import ArticlePageRight from '@/components/ArticlePageRight.vue';
import Hero from '@/components/Hero.vue';
import Contact from '@/components/Contact.vue';

const hero = createApp(Hero);
hero.mount('#hero');

const products = createApp(Products);
products.mount('#products');

const aboutUs = createApp(AboutUs);
aboutUs.mount('#aboutUs');

const articlePageRight = createApp(ArticlePageRight);
articlePageRight.mount('#articlePageRight');

const featureHighlights = createApp(FeatureHighlights);
featureHighlights.mount('#featureHighlights');

const privacyPolicyPage = createApp(PrivacyPolicyPage);
privacyPolicyPage.mount('#privacyPolicyPage');
const termsOfServicePage = createApp(TermsOfServicePage);
termsOfServicePage.mount('#termsOfServicePage');
const newsroom = createApp(Newsroom);
newsroom.mount('#newsroom');
const contact = createApp(Contact).use(install, {
  sitekey: import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY,
  cnDomains: false, // Optional, If you use in China, set this value true
});
contact.mount('#contact');
